<template>
  <trac-loading class="pt-64" v-if="loading" />
  <main v-else>
    <trac-back-button>Payments</trac-back-button>
    <div class="mt-12 flex items-center">
      <div>
        <h2 class="tiny text-primaryGray">Net Payout</h2>
        <h1 class="text-base text-primaryBlue mt-2 font-medium">
          {{ payoutDetails.net_payout | formatPrice }}
        </h1>
      </div>
    </div>
    <div class="shadow-md s mt-8 p-6 rounded-lg mr-4 md:mr-0">
      <h2>PAYOUT Details</h2>
      <div class="grid grid-cols-2 mt-5">
        <div class="text-left">
          <span class="text-xs block sm text-gray-500">Transaction amount</span>
          <p class="inline text-xs mt-1">
            {{ payoutDetails.transaction_amount | formatPrice }}
          </p>
        </div>
        <div class="text-right md:text-left">
          <span class="text-xs block text-gray-500">Total Fees</span>
          <p class="inline text-xs mt-1">
            {{ payoutDetails.charges.totalFee | formatPrice }}
          </p>
        </div>
        <!-- <div class="text-left">
          <span class="text-xs block text-gray-500">Status</span>
          <p
            :class="[
              {
                'bg-green-300 text-green-700 ': payoutDetails.status == 'Paid',
              },
              {
                'bg-yellow-200 text-yellow-500 ':
                  payoutDetails.status == 'Pending',
              },
              {
                'bg-red-200 text-red-500 ': payoutDetails.status == 'Failed',
              },
            ]"
            class="inline mt-1 text-xs py-1 px-2 rounded-lg font-bold"
          >
            {{ payoutDetails.status }}
          </p> -->
        </div>
      </div>

      <hr class="md:my-10 md:w-4/5 border-gray-400 border-opacity-50 mr-4 md:mr-0" />

      <div class="grid grid-cols-3 mb-10">
        <!-- <div class="text-left">
          <span class="text-xs block text-gray-500">Settlement Date</span>
          <p class="inline text-xs mt-1">
            {{
              checkDate(
                moment(payoutDetails.settlement_date).format("Do-MMM-YYYY")
              )
            }}
          </p>
        </div> -->
        <!-- <div class="text-left">
          <span class="text-xs block text-gray-500">Receiver</span>
          <p class="inline text-xs mt-1">
            {{ payoutDetails.receiver_details.account_number }} -
            {{ payoutDetails.receiver_details.bank_Name }}
          </p>
        </div> -->
      </div>

    <div class="grid md:grid-cols-2 mt-8 gap-10 mb-10 pr-4 md:pr-0">
      <div class="">
        <div class="shadow-md p-6 rounded-lg">
          <div class="flex justify-between py-4">
            <h3 class="text-xs font-bold">TRANSACTIONS</h3>
            <span class="text-primaryBlue text-xs cursor-pointer"
              >View All</span
            >
          </div>
          <div
            v-for="(transaction, i) in payoutDetails.list_of_payments"
            :key="i"
            class="flex justify-between py-2 border-b-2 border-gray-100"
          >
            <div class="">
              <p class="text-xs">{{ transaction.customer_name }}</p>
              <span class="text-xs text-gray-600">
                {{
                  moment(transaction.date_of_payment_by_customer).format(
                    "Do-MMM-YYYY"
                  )
                }}
                |
              </span>
              <span class="text-xs text-gray-600">{{
                transaction.payment_method
              }}</span>
            </div>
            <p class="font-bold text-xs">
              {{ transaction.amount_paid | formatPrice }}
            </p>
          </div>
          <div class="text-left mt-3">
            <span class="text-xs block text-gray-500">Transaction total</span>
            <p class="inline text-xs mt-1 font-bold">
              {{ total | formatPrice }}
            </p>
          </div>
        </div>
      </div>

      <div class="">
        <hr class="md:my-6 md:w-4/5 border-gray-400 border-opacity-50" />

        <div class="grid grid-cols-2 mt-5">
          <div class="text-left">
            <span class="text-xs block text-gray-500">Transaction fee</span>
            <p class="inline text-xs mt-1">
              {{ payoutDetails.charges.transactionFee }}
            </p>
          </div>
          <div class="text-right md:text-left">
            <span class="text-xs block text-gray-500">VAT</span>
            <p class="inline text-xs mt-1">
              {{ payoutDetails.charges.vatFee.toFixed(3) }}
            </p>
          </div>
          <div class="text-left mt-4">
            <span class="text-xs block text-gray-500">Stamp Duty</span>
            <p class="inline text-xs mt-1">
              {{ payoutDetails.charges.stampDuty }}
            </p>
          </div>
          <div class="text-right md:text-left mt-4">
            <span class="text-xs block text-gray-500">Terminal Lease</span>
            <p class="inline text-xs mt-1">
              {{ payoutDetails.charges.loanFee }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
      payoutDetails: {},
      moment,
      loading: false,
    };
  },
  methods: {
    async paymentLogRequest(x) {
      this.loading = true;
      let res = await this.$store.dispatch("GET_PAYOUT_DETAILS", x);
      if (res.status) {
        this.loading = false;
        this.payoutDetails = res.data.item;
      }
    },
    checkDate(x) {
      if (x.includes("0001")) {
        return "To be paid";
      } else {
        return x;
      }
    },
  },
  computed: {
    total() {
      let k = this.payoutDetails.list_of_payments.reduce(
        (a, { amount_paid }) => a + amount_paid,
        0
      );
      return k;
    },
    totalFees() {
      if (this.payoutDetails)
        return (
          this.payoutDetails.charges.transactionFee +
          +this.payoutDetails.charges.vatFee.toFixed(3) +
          +this.payoutDetails.charges.stampDuty +
          +this.payoutDetails.charges.loanFee
        );
    },
  },
  async created() {
    let payload = this.$route.params.payoutId;
    await this.paymentLogRequest(payload);
  },
};
</script>

<style></style>
